export default {
  colors: {
    primary: '#000033',
    primary2: '#0D0D3D',
    primary3: '#242450',
    cardB1: '#0D0D3D',
    cardB2: '#24244F',
    cardB3: '#2F2F58',
    cardImg: '0,0,51,0.7',
    arrow: '#808099',
    cardTxt: '#C3C3C3',
    notifications: '#FFE100',
    graph: '#00AB2B',
    title: '#fff',
    text: '#C3C3C3',
    background: '#F8F8F8',
    secondary: '#FBE306',
    gray: '#aaa',
    black: '#000',
    iconsOff: '#808099',
    redAlert: '#FF4444',
    red: '#DB5148',
    level1: '#004311',
    level2: '#006D1C',
    level3: '#00AB2B',
    level4: '#00FF40',
    check: '#1B63F2',

    titleCard: '#878B99',
    grayProg: '#878B99',

  },
};
