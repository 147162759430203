import styled from 'styled-components';
import InputMask from 'react-input-mask';
import theme from '../../theme';

type PropTypeAlreadyCreditCardSavedNumberContainer = {
  hasInstallments: boolean
}

export const FlagSelect = styled.select`
  cursor: pointer;
  width: 100%;
  padding: 10px;
  margin: 10px 0 10px 0;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: ${theme.colors.cardB3};
`;

export const FlagSelectOption = styled.option`
  cursor: pointer;
  color: #fff;
`;

export const InstallmentsSelect = styled.select`
  cursor: pointer;
  width: 100%;
  padding: 10px;
  margin: 10px 0 10px 0;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: ${theme.colors.cardB3};
`;

export const InstallmentsSelectOption = styled.option`
  cursor: pointer;
  color: #fff;
`;

export const CardSecurityCodeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
`;

export const CardSecurityCode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 667px) {
    align-items: flex-end;

    input {
      width: 90%;
    }
  }
`;

export const CardNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 85%;

  @media (max-width: 667px) {
    label {
      font-size: 11pt;
    }
  }
`;
export const CardFlag = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 15%;
`;

export const CardValidDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 667px) {
    label {
      font-size: 11pt;
    }
  }
`;

export const InputText = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0 10px 0;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: ${theme.colors.cardB3};

  ::placeholder {
    color: #fff;
  }
`;

export const InputMasked = styled(InputMask)`
  width: 100%;
  padding: 10px;
  margin: 10px 0 10px 0;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: ${theme.colors.cardB3};

  ::placeholder {
    color: #fff;
  }
`;

export const ProductName = styled.p`
  color: #fff;
  font-size: 12pt;
`;

export const OptionTitle = styled.p`
  color: #fff;
  font-size: 12pt;
`;

export const OptionContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

export const ErrorMsg = styled.div`
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const AuthButtons = styled.div`
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const AuxTextFooter = styled.div`
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;

  a {
    color: #fff;
  }

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const SSLStamp = styled.div`
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  img {
    height: 150px;
  }

  @media (max-width: 667px) {
    max-width: 350px;

    img {
      height: 120px;
    }
  }
`;

export const ValueDetails = styled.div`
    width: 100%;
    max-width: 400px;
    margin: 20px auto 20px auto;
    border: 1px solid #000;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;

    p {
      margin-bottom: 5px;
      color: #fff;
    }
`;

export const Steps = styled.div`
    background: ${theme.colors.cardB2};
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    padding: 5px;

    @media (max-width: 667px) {
      font-size: 10pt;
    }
`;

export const Discount = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    color: #fff;
`;

export const CouponFill = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    color: #fff;
    transition: opacity 0.3s ease, height 0.3s ease;

    input {
      background-color: ${theme.colors.cardB3};
      font-size: 12pt;
      font-weight: bold;
      color: #fff;
      border-radius: 0;
    }

    input::placeholder {
      color: #fff;
    }

    button {
      border: none;
      background-color: #fff;
      color: ${theme.colors.cardB2};
      border: none;
      padding: 10px;
      height: 40px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
      }
    }
`;

export const TotalValue = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    color: #fff;
`;

export const TotalOldValue = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    color: #fff;
    font-size: 11pt;
    text-decoration: line-through;
`;

export const RenewDate = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    color: #fff;
    font-size: 10pt;
`;

export const RenewValue = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    color: #fff;
    font-size: 10pt;
`;

export const Container = styled.section`
  background: ${theme.colors.cardB2};
  margin-top: 10px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
  border: none;

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const PaymentMethodsContainer = styled.section`
  background: ${theme.colors.cardB2};
  margin-top: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const AddressButtonContainer = styled.section`
  background: ${theme.colors.cardB2};
  margin-top: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const PartnerDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  label {
    color: #fff;
    font-size: 16px;
  }

  input,
  select {
    padding: 15px;
    width: 100%;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: ${theme.colors.cardB3};
    color: #fff;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
    background: #fff;
    color: ${theme.colors.primary};
    border: none;
    transition: 0.3s;

    &:hover {
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const PaymentOption = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  color: #fff;
  text-align: left;
  line-height: 1.8;
  input, label {
    cursor: pointer;
  }
`;

export const PaymentOptionDetails = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  color: #fff;
`;

export const GenericOptionDetails = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  color: #fff;
  text-align: start;
`;

export const ContainerFlags = styled.div`
   width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
`;

export const ListFlags = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export const LoginButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
    background: ${theme.colors.primary};
    color: #fff;
    border: 1px solid #fff;
    transition: 0.3s;

    &:hover {
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
    }
`;

export const SignUpButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
    background: #fff;
    color: ${theme.colors.primary};
    border: none;
    transition: 0.3s;

    &:hover {
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
    }
`;

export const PayNow = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
    background: #fff;
    color: ${theme.colors.cardB2};
    border: none;
    transition: 0.3s;

    &:hover {
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
    }
`;

export const DiscountButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
    background: ${theme.colors.cardB2};
    color: #fff;
    border: 1px solid #fff;
    transition: 0.3s;

    &:hover {
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
    }
`;

export const DiscountButtonRemove = styled.button`
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  background: transparent;
  color: #ff0000;
  border: none;
`;

export const AlreadyCreditCardSavedTitle = styled.p`
  font-weight: bold;
  margin-bottom: 15px;
`;

export const AlreadyCreditCardSavedSubtitle = styled.p`
  margin-bottom: 15px;
`;

// eslint-disable-next-line no-unused-vars
export const AlreadyCreditCardSavedNumberContainer = styled.div.attrs((props: PropTypeAlreadyCreditCardSavedNumberContainer) => ({})) < PropTypeAlreadyCreditCardSavedNumberContainer >`
  width: 100%; 
  margin-bottom: ${(props) => (props.hasInstallments ? '20px' : '0px')};
  display: flex; 
  flex-direction: row; 
  justify-content: space-between;
  align-items: center;
`;

export const AlreadyCreditCardSavedNumber = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const AlreadyCreditCardSavedSwitchButton = styled.span`
  color: #fff; 
  text-decoration: underline; 
  cursor: pointer;
`;

export const SaveAddressButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    font-size: 14px;
    cursor: pointer;
    background: #fff;
    color: ${theme.colors.cardB2};
    border: none;
    transition: 0.3s;

    &:hover {
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
    }
`;

export const HasSubscriptionValidMessageContainer = styled.div`
  padding: 10px;
  background-color: ${theme.colors.cardB2};
  border-radius: 10px;
  margin-top: 20px;
  border: none;
`;

export const HasSubscriptionValidMessage = styled.p`
  font-size: 14px;
  color: #ff0000;
  font-weight: bold;
`;

export const HasSubscriptionValidMessageMailLink = styled.a`
  font-size: 14px;
  color: #fff;
  font-weight: bold;
`;
