import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import InputMask from 'react-input-mask';
import theme from '../../theme';

type PropTypeShowModal = {
  showModal: boolean
}

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;

// eslint-disable-next-line no-unused-vars
export const ModalWrapper = styled.div.attrs((props: PropTypeShowModal) => ({})) < PropTypeShowModal >`
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 100%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: ${theme.colors.primary};
  z-index: 10;
  border-radius: 10px;
  margin-top: 10px;

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  color: #fff;
`;

export const AddressForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;

  label {
    color: #fff;
    font-size: 16px;
  }

  input, select {
    padding: 15px;
    width: 100%;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: ${theme.colors.cardB3};
    color: #fff;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
    background: #fff;
    color: ${theme.colors.primary};
    border: none;
    transition: 0.3s;

    &:hover {
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const Logo = styled.img`
  max-height: 150px;
  max-width: 150px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const CityState = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
  }

  div[id="state"] {
    width: 30%;
  }

  div[id="city"] {
    width: 65%;
  }
`;

export const NumberNeighborhood = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
  }

  div[id="number"] {
    width: 30%;
  }

  div[id="neighborhood"] {
    width: 65%;
  }
`;

export const InputMasked = styled(InputMask)``;
