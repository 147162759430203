import styled from 'styled-components';
import theme from '../../theme';

type PropTypeShowModal = {
  showModal: boolean
}

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

// eslint-disable-next-line no-unused-vars
export const ModalWrapper = styled.div.attrs((props: PropTypeShowModal) => ({})) < PropTypeShowModal >`
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 100%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: ${theme.colors.primary};
  z-index: 10;
  border-radius: 10px;

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.p`
  margin-bottom: 20px;
  margin-top: 15px;
  font-size: 14pt;
  color: #fff;
`;

export const Email = styled.p`
  color: #fff;
  margin-bottom: 10px;
`;

export const CPF = styled.p`
  color: #fff;
`;

export const Phone = styled.p`
  color: #fff;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  font-size: 14px;
  margin-top: 20px;
  cursor: pointer;
  background: #fff;
  color: ${theme.colors.primary};
  border: none;
`;

export const FixButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  font-size: 14px;
  margin-top: 20px;
  cursor: pointer;
  background: ${theme.colors.primary};
  color: #fff;
  border: 1px solid #fff;
`;
