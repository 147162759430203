import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';
import axios from 'axios';
import {
  Background,
  ModalContent,
  ModalWrapper,
  CloseModalButton,
  LoginForm,
  Logo,
  PasswordRecoveryButton,
} from './styles';
import logo from '../../assets/logo.png';
import Loader from '../Loader';
import { signInRequest, setErrorMessage, googleLogin } from '../../store/ducks/auth/actions';
import { IStore } from '../../contracts';
import { IProps } from './contracts';
import SocialLoginButton from '../SocialLoginButton';
import { LOGIN_API_URL } from '../../services/api';

export const SignInModal = ({ showModal, setShowModal }: IProps) => {
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const loading = useSelector((state: IStore) => state.auth.loading);
  const errorMessage = useSelector((state: IStore) => state.auth.errorMessage);
  const token = useSelector((state: IStore) => state.auth.token);

  const animation = useSpring({
    to: {
      config: {
        duration: 250,
      },
      opacity: showModal ? 1 : 0,
      transform: showModal ? 'translateY(0%)' : 'translateY(-100%)',
    },
  });

  const closeModal = (e: { target: any; }) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal],
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress],
  );

  function handleSubmit(event: { preventDefault: () => void; }) {
    event.preventDefault();

    if (!email || !password) {
      dispatch(setErrorMessage('Preencha todos os campos'));
      return;
    }

    dispatch(signInRequest(email, password));
  }

  const googleLoginHandle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);

      const data = {
        name: userInfo.name ?? `${userInfo.givenName} ${userInfo.familyName}`,
        email: userInfo.email,
        token: tokenResponse.access_token,
        photo: userInfo.picture ?? '',
        provider: 'google',
        origin: 'WEB',
      };

      dispatch(googleLogin(data));
    },
  });

  useEffect(() => {
    if (token) {
      setEmail('');
      setPassword('');
      setShowModal(false);
    }
  }, [token, setShowModal]);

  useEffect(() => {
    localStorage.setItem('redirect-url', window.location.href);
  }, []);

  return (
    <>
      {loading && <Loader />}
      {showModal ? (
        <Background ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <ModalContent>
                <Logo src={logo} alt="logo" />

                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

                <LoginForm onSubmit={(e) => handleSubmit(e)}>
                  <label htmlFor="email">Email</label>
                  <input type="email" id="email" placeholder="Digite seu email" value={email} onChange={(e) => setEmail(e.target.value)} />

                  <label htmlFor="password">Senha</label>
                  <input type="password" id="password" placeholder="Digite sua senha" value={password} onChange={(e) => setPassword(e.target.value)} />

                  <br />

                  <button type="submit">
                    <strong>Acessar</strong>
                  </button>

                  <SocialLoginButton
                    title="Fazer login com Google"
                    loading={false}
                    type="google"
                    onClick={() => googleLoginHandle()}
                    disabled={false}
                  />

                  <AppleLogin
                    clientId="com.corridaperfeita.web"
                    redirectURI={`${LOGIN_API_URL}/apple-web-pay`}
                    callback={() => { }}
                    responseType="code id_token"
                    responseMode="form_post"
                    scope="name email"
                    render={(renderProps) => (
                      <SocialLoginButton
                        title="Fazer login com Apple"
                        loading={false}
                        type="apple"
                        onClick={renderProps.onClick}
                        disabled={false}
                      />
                    )}
                  />

                  <PasswordRecoveryButton href={`${process.env.REACT_APP_APP2_BASE_URL}/solicitar-recuperar-senha`} target="_blank" rel="noopener noreferrer">
                    Recuperar senha
                  </PasswordRecoveryButton>
                </LoginForm>
              </ModalContent>
              <CloseModalButton
                aria-label="Close modal"
                onClick={() => setShowModal((prev: boolean) => !prev)}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};
