import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.section`
  background: ${theme.colors.cardB2};
  margin-top: 10px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
  border: none;

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const Title = styled.p`
  color: #fff;
  font-size: 12pt;
  margin-bottom: 20px;
`;

export const Buttons = styled.div`
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #fff;
  text-align: center;

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const ConfirmButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    border-radius: 10px;
    font-size: 14px;
    margin-top: 20px;
    background: #fff;
    color: ${theme.colors.primary};
    border: none;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
    }
`;

export const Item = styled.button`
    cursor: pointer;
    width: 100%;
    height: 45px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: ${theme.colors.cardB3};
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
`;

export const Radio = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12px;
    width: 12px;
    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    border-color: ${theme.colors.title};
    padding: 5px;
`;

export const RadioContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 10%;
    margin-left: 20px;
`;

export const RadioInner = styled.div`
    height: 10px;
    width: 10px;
`;

export const Label = styled.p`
    color: ${theme.colors.title};
    font-weight: bold;
    margin-left: 10px;
`;

export const LabelContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
`;

export const Input = styled.input`
  height: 20px;
  border-radius: 5px;
  margin-left: 10px;
  border: none;
  padding: 5px;
`;
