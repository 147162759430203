/* eslint-disable no-unused-vars */
export enum AuthTypes {
    SIGN_IN_REQUEST = '@auth/SIGN_IN_REQUEST',
    SIGN_IN_SUCCESS = '@auth/SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE = '@auth/SIGN_IN_FAILURE',
    SET_ERROR_MESSAGE = '@auth/SET_ERROR_MESSAGE',
    PERSIST_REHYDRATE = 'persist/REHYDRATE',
    SIGN_OUT = '@auth/SIGN_OUT',
    SET_PAYMENT_TOKEN = '@auth/SET_PAYMENT_TOKEN',
    UPDATE_USER_PROFILE_REQUEST = '@auth/UPDATE_USER_PROFILE_REQUEST',
    UPDATE_USER_PHONE = '@auth/UPDATE_USER_PHONE',
    SIGN_IN_GOOGLE = '@auth/SIGN_IN_GOOGLE',
    SIGN_IN_APPLE = '@auth/SIGN_IN_APPLE',
}
