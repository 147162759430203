import React from 'react';
import { FaUser } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Container, AuthArea } from './styles';
import logo from '../../assets/logo.png';
import { signOut } from '../../store/ducks/auth/actions';
import { IStore } from '../../contracts';

function Header() {
  const dispatch = useDispatch();
  const { token, profile } = useSelector((state: IStore) => state.auth);

  return (
    <>
      {token && (
        <AuthArea>
          <FaUser />
          &nbsp;
          {profile.email}
          &nbsp;
          <button onClick={() => dispatch(signOut())} type="button">[sair]</button>
        </AuthArea>
      )}
      <Container>
        <img src={logo} alt="CP checkout" />
      </Container>
    </>
  );
}

export default Header;
