import React from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import PaymentDetails from '../pages/PaymentDetails';
import PaymentSuccess from '../pages/PaymentSuccess';
import ErrorPage from '../pages/ErrorPage';
import RouteWrapper from './RouteWrapper';
import LoginRedirect from '../pages/LoginRedirect';
import RedirecionadoPage from '../pages/RedirectPage';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <RouteWrapper path="/pay/mobile/product/:product/:tokenMobile" exact component={PaymentDetails} isPrivate={false} />
        <RouteWrapper path="/login/apple" exact component={LoginRedirect} isPrivate={false} />
        <RouteWrapper path="/pay/product/:product" exact component={PaymentDetails} isPrivate={false} />
        <RouteWrapper path="/pay/product/:product/:coupon" exact component={PaymentDetails} isPrivate={false} />
        <RouteWrapper path="/error" exact component={ErrorPage} isPrivate={false} />
        <RouteWrapper path="/redirecionado" exact component={RedirecionadoPage} isPrivate={false} />
        <RouteWrapper path="/pay/success/:product" exact component={PaymentSuccess} isPrivate />
        <RouteWrapper path="/" exact={false} component={() => <Redirect to="/error" />} isPrivate={false} />
      </Switch>
    </BrowserRouter>
  );
}
