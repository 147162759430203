import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import logo from '../../assets/logo_corridaperfeita_branco.png';
import { Container, Logo, Text } from './styles';
import { RIProps } from '../PaymentDetails/contracts';
import { appleLogin } from '../../store/ducks/auth/actions';

export default function LoginRedirect(props: RIProps) {
  const params = new URLSearchParams(props?.location?.search);
  const redirect_url = localStorage.getItem('redirect-url');

  const token = params.get('token');
  const err = params.get('err');

  const dispatch = useDispatch();

  const login = async () => {
    if (err) {
      // eslint-disable-next-line no-alert
      alert(err);
      window.location.href = '/';
      return;
    }
    if (token) {
      const data = JSON.parse(atob(token.split('.')[1]));
      await dispatch(appleLogin(data.uid, token, redirect_url));
    }
  };

  useEffect(() => {
    login();
  }, [token]);

  return (
    <Container>
      <Logo src={logo} alt="logo" />
      <Text>{`Redirecionando para ${redirect_url ?? 'o Corrida Perfeita'}`}</Text>
    </Container>
  );
}
