import React, { useState } from 'react';
import {
  Buttons, ConfirmButton, Container, Input, Item, Label, LabelContainer, Radio, RadioContainer, RadioInner, Title,
} from './styles';
import theme from '../../theme';
import { api2 } from '../../services/api';

const options = [
  'Busca no Google',
  'YouTube',
  'Instagram',
  'Indicação',
  'Aplicativo',
  'Evento (prova ou treino)',
];

interface IProps {
  action: () => void;
}

function MetUsAt({ action }: IProps) {
  const [selectedOption, setSelectedOption] = useState('');
  const [otherOptionText, setOtherOptionText] = useState('');

  const handleSaveOption = async () => {
    const payload = {
      met_us_at: selectedOption,
    };

    if (selectedOption === 'Outro' && otherOptionText.length) {
      payload.met_us_at = otherOptionText;
    }

    try {
      await api2.put('/user_metadata', payload);
    } finally {
      action?.();
    }
  };

  return (
    <>
      <Container>
        <Title>Como foi que nos conheceu?</Title>

        {
          options.map((item) => (
            <Item
              key={item}
              onClick={() => setSelectedOption(item)}
              style={{ borderColor: `${selectedOption === item ? theme.colors.title : theme.colors.cardB3}` }}
            >
              <RadioContainer>
                <Radio
                  style={{
                    backgroundColor: selectedOption === item ? theme.colors.title : theme.colors.cardB3,
                  }}
                >
                  <RadioInner
                    style={{
                      backgroundColor: selectedOption === item ? theme.colors.title : theme.colors.cardB3,
                    }}
                  />
                </Radio>
              </RadioContainer>

              <LabelContainer>
                <Label>{item}</Label>
              </LabelContainer>
            </Item>
          ))
        }

        <Item
          onClick={() => setSelectedOption('Outro')}
          style={{ borderColor: `${selectedOption === 'Outro' ? theme.colors.title : theme.colors.cardB3}` }}
        >
          <RadioContainer>
            <Radio
              style={{
                backgroundColor: selectedOption === 'Outro' ? theme.colors.title : theme.colors.cardB3,
              }}
            >
              <RadioInner
                style={{
                  backgroundColor: selectedOption === 'Outro' ? theme.colors.title : theme.colors.cardB3,
                }}
              />
            </Radio>
          </RadioContainer>

          <LabelContainer>
            <Label>Outro</Label>
            <Input type="text" value={otherOptionText} onChange={(e) => setOtherOptionText(e.target.value)} />
          </LabelContainer>
        </Item>
      </Container>

      <Buttons>
        <ConfirmButton
          onClick={handleSaveOption}
        >
          Confirmar
        </ConfirmButton>
      </Buttons>
    </>
  );
}

export default MetUsAt;
