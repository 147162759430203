import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Background,
  ModalContent,
  ModalWrapper,
  CloseModalButton,
  LoginForm,
  Logo,
  InputMasked,
} from './styles';
import logo from '../../assets/logo.png';
import Loader from '../Loader';
import { setErrorMessage } from '../../store/ducks/auth/actions';
import { IStore } from '../../contracts';
import { IProps } from './contracts';
import api from '../../services/api';
import { errorMessages } from '../../constants/messages';

const CPFInput = (props: any) => (
  <InputMasked
    mask="999.999.999-99"
    defaultValue={props.defaultValue}
    onChange={props.onChange}
  />
);

export const SaveCpfModal = ({ showModal, setShowModal, finishPayment }: IProps) => {
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const [cpf, setCpf] = useState('');
  const [loading, setLoading] = useState(false);
  const errorMessage = useSelector((state: IStore) => state.auth.errorMessage);
  const userId = useSelector((state: IStore) => state.auth.profile?.id);

  const animation = useSpring({
    to: {
      config: {
        duration: 250,
      },
      opacity: showModal ? 1 : 0,
      transform: showModal ? 'translateY(0%)' : 'translateY(-100%)',
    },
  });

  const closeModal = (e: { target: any; }) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal],
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress],
  );

  async function handleSubmit(event: { preventDefault: () => void; }) {
    event.preventDefault();

    if (!cpf) {
      dispatch(setErrorMessage('Preencha o campo'));
      return;
    }

    setLoading(true);

    try {
      await api.put(`users/${userId}`, { cpf: cpf.replaceAll('.', '').replaceAll('-', '') });

      setLoading(false);

      setShowModal(false);

      await finishPayment();
    } catch (error) {
      setLoading(false);

      toast.error(errorMessages.UPDATE_USER_CPF_FAILURE);
    }
  }

  return (
    <>
      {loading && <Loader />}
      {showModal ? (
        <Background onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <ModalContent>
                <Logo src={logo} alt="logo" />

                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

                <LoginForm onSubmit={(e) => handleSubmit(e)}>
                  <label htmlFor="cpf">CPF</label>
                  <CPFInput
                    defaultValue={cpf}
                    onChange={(e: any) => setCpf(e.target.value)}
                    name="cpf"
                    id="cpf"
                  />

                  <button type="submit">
                    <strong>Continuar</strong>
                  </button>
                </LoginForm>
              </ModalContent>
              <CloseModalButton
                aria-label="Close modal"
                onClick={() => setShowModal((prev: boolean) => !prev)}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};
