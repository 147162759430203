import React from 'react';
import { Container, Spinner } from './styles';

function Loader() {
  return (
    <Container>
      <div>
        <Spinner />
        <p>Aguarde...</p>
      </div>
    </Container>
  );
}

export default Loader;
