import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import {
  Background,
  ModalContent,
  ModalWrapper,
  CloseModalButton,
  LoginForm,
  Logo,
} from './styles';
import logo from '../../assets/logo.png';
import Loader from '../Loader';
import { setErrorMessage, updateUserPhone } from '../../store/ducks/auth/actions';
import { IStore } from '../../contracts';
import { IProps } from './contracts';
import api from '../../services/api';
import { errorMessages } from '../../constants/messages';
import theme from '../../theme';

export const SavePhoneModal = ({
  showModal, setShowModal, finishPayment, update, proceedPay,
}: IProps) => {
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const errorMessage = useSelector((state: IStore) => state.auth.errorMessage);
  const userId = useSelector((state: IStore) => state.auth.profile?.id);

  useEffect(() => {
    if (update) {
      api.get(`/users/${userId}`)
        .then((response) => {
          setPhone(response.data.phone);
        });
    }
  }, [update]);

  const animation = useSpring({
    to: {
      config: {
        duration: 250,
      },
      opacity: showModal ? 1 : 0,
      transform: showModal ? 'translateY(0%)' : 'translateY(-100%)',
    },
  });

  const closeModal = (e: { target: any; }) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal],
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress],
  );

  async function handleSubmit(event: { preventDefault: () => void; }) {
    event.preventDefault();

    if (!phone) {
      dispatch(setErrorMessage('Preencha o campo'));
      return;
    }

    setLoading(true);

    try {
      await api.put(`users/${userId}`, { phone });

      dispatch(updateUserPhone(phone));

      setLoading(false);

      setShowModal(false);

      if (proceedPay) await finishPayment();
    } catch (error) {
      setLoading(false);

      toast.error(errorMessages.UPDATE_USER_PHONE_FAILURE);
    }
  }

  return (
    <>
      {loading && <Loader />}
      {showModal ? (
        <Background onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <ModalContent>
                <Logo src={logo} alt="logo" />

                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

                <LoginForm onSubmit={(e) => handleSubmit(e)}>
                  <label htmlFor="phone">Telefone celular</label>
                  <PhoneInput
                    country="br"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    countryCodeEditable={false}
                    inputProps={{
                      name: 'phone',
                      id: 'phone',
                      required: true,
                      autoFocus: true,
                    }}
                    containerStyle={{
                      width: '100%',
                      height: '47px',
                      border: 'none',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                    buttonStyle={{
                      border: 'none',
                      backgroundColor: theme.colors.cardB3,
                    }}
                    inputStyle={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      backgroundColor: theme.colors.cardB3,
                      color: '#fff',
                    }}
                  />

                  <button type="submit">
                    <strong>Continuar</strong>
                  </button>
                </LoginForm>
              </ModalContent>
              <CloseModalButton
                aria-label="Close modal"
                onClick={() => setShowModal((prev: boolean) => !prev)}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};
