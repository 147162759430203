import React from 'react';
import logo from '../../assets/logo_corridaperfeita_branco.png';
import { Container, Logo, Text } from './styles';

function ErrorPage() {
  return (
    <Container>
      <Logo src={logo} alt="logo" />
      <Text>Página não encontrada.</Text>
    </Container>
  );
}

export default ErrorPage;
