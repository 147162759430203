import {
  call, put,
} from 'redux-saga/effects';
import { errorMessages } from '../../../constants/messages';
import api, { LoginApi, api2 } from '../../../services/api';
import {
  signInSuccess, signInFailure, signInRequest, persistRehydrate, updateUserProfileRequest, googleLogin, appleLogin,
} from './actions';
import { Profile } from '../../../contracts';

export function* signIn({ payload }: ReturnType<typeof signInRequest>) {
  try {
    const { email, password } = payload;

    const { data } = yield call(api.post, '/sessions', { email, password });

    api.defaults.headers.Authorization = `Bearer ${data.auth.token}`;
    api2.defaults.headers.Authorization = `Bearer ${data.auth.token}`;

    const profile: Profile = {
      id: data.user.id,
      name: data.user.name,
      email: data.user.email,
      phone: data.user.phone,
    };

    yield put(signInSuccess(data.auth.token, profile));
  } catch (error) {
    yield put(signInFailure(errorMessages.SIGN_IN_FAILURE));
  }
}

export function setToken({ payload }: ReturnType<typeof persistRehydrate>) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api2.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function* updateUserProfile({ payload }: ReturnType<typeof updateUserProfileRequest>) {
  const { id, profile, address } = payload;
  if (profile.name || profile.cpf || profile.phone || address) {
    yield call(api.put, `/users/${id}`, { ...profile, address });
  }
}

export function* googleSignIn({ payload }: ReturnType<typeof googleLogin>) {
  try {
    const { userData } = payload;

    const { data } = yield call(LoginApi.post, '/google-login', userData);

    api.defaults.headers.Authorization = `Bearer ${data.auth.token}`;
    api2.defaults.headers.Authorization = `Bearer ${data.auth.token}`;

    const profile: Profile = {
      id: data.user.id,
      name: data.user.name,
      email: data.user.email,
      phone: data.user.phone,
    };

    yield put(signInSuccess(data.auth.token, profile));
  } catch (error) {
    yield put(signInFailure(errorMessages.SIGN_IN_FAILURE));
  }
}

export function* appleSignIn({ payload }: ReturnType<typeof appleLogin>) {
  try {
    const { userId, token, redirect } = payload;

    api.defaults.headers.common = { Authorization: `Bearer ${token}` };
    api2.defaults.headers.common = { Authorization: `Bearer ${token}` };

    const { data } = yield call(api.get, `/users/${userId}`);

    const profile: Profile = {
      id: data.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
    };

    yield put(signInSuccess(token, profile));
    if (redirect) {
      window.location.href = redirect;
    }
  } catch (error) {
    yield put(signInFailure(errorMessages.SIGN_IN_FAILURE));
  }
}
