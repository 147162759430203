/* eslint-disable no-unused-vars */
export enum errorMessages {
  SIGN_IN_FAILURE = 'Email ou senha incorretos, verifique e tente novamente',
  GET_PAYMENT_METHODS_FAILURE = 'Ocorreu um erro ao carregar as informações, por favor atualize a página',
  PROCESS_PAYMENT_FAILURE = 'Ocorreu um erro ao concluir seu pagamento, por favor atualize a página',
  USER_NOT_FOUND = 'Usuário não encontrado',
  USER_HAS_NOT_PHONE = 'Cadastre um telefone',
  UPDATE_USER_PHONE_FAILURE = 'Ocorreu um erro ao salvar o telefone, tente novamente',
  UPDATE_USER_CPF_FAILURE = 'Ocorreu um erro ao salvar o CPF, tente novamente',
  ZIPCODE_NOT_FOUND = 'CEP não encontrado',
  USER_ALREADY_EXISTS = 'Já existe um usuário cadastrado com esse email',
  USER_HAS_NOT_CPF = 'Cadastre seu CPF',
  SAVE_USER_ADDRESS_FAILURE = 'Ocorreu um erro ao salvar seu endereço, tente novamente',
  USER_HAS_NOT_ADDRESS = 'Cadastre seu endereço',
  USER_HAS_NOT_ADDRESS_CPF = 'Cadastre seu cpf e endereço',
  INVALID_CARD_NUMBER = 'Número de cartão inválido, verifique se está correto e tente novamente',
  INVALID_CARD_EXPIRATION = 'Data de validade expirada, verifique se está correta e tente novamente',
  INVALID_CARD_CVV = 'Código de segurança (CVV) inválido, verifique se está correto e tente novamente',
  INVALID_PHONE_NUMBER = 'Telefone inválido, atualize e tente novamente',
  INVALID_CPF = 'CPF inválido, atualize e tente novamente',
  COUPON_NOT_ALLOWED = 'Cupom não permitido'
}

export enum errorMessageTypes {
  USER_NOT_FOUND = 'user_not_found',
  USER_HAS_NOT_PHONE = 'user_has_not_phone',
  USER_ALREADY_EXISTS = 'user_already_exists',
  USER_HAS_NOT_CPF = 'user_has_not_cpf',
  USER_HAS_NOT_ADDRESS = 'user_has_not_address',
  USER_HAS_NOT_ADDRESS_CPF = 'user_has_not_address_cpf',
  USER_HAS_SUBSCRIPTION_VALID = 'user_has_subscription_valid',
  COUPON_NOT_ALLOWED = 'coupon_not_allowed'
}
