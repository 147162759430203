import { format, addMonths } from 'date-fns';

export function convertRecurrenceToPaymentPeriodInstallments(recurrence: number) {
  switch (recurrence) {
    case 1:
      return 'Pagamento mensal';
    case 3:
      return 'Pagamento trimestral';
    case 12:
      return 'Pagamento anual';
    default:
      return '';
  }
}

export function convertRecurrenceToPaymentNextBilling(recurrence: number) {
  return format(addMonths(new Date(), recurrence), 'dd/MM/yyyy');
}

export function formatCouponMessageError(error: string) {
  switch (error) {
    case 'coupon_not_found':
      return 'Cupom não encontrado';
    case 'coupon_inactive':
      return 'Cupom não encontrado';
    case 'coupon_limit_expired':
      return 'Cupom expirado';
    case 'coupon_allowed_for_restricted_emails':
      return 'Cupom não encontrado';
    case 'coupon_not_allowed_for_this_plan':
      return 'Cupom não aplicável para este produto';
    case 'coupon_allowed_for_restricted_plans':
      return 'Cupom não aplicável para este produto';
    case 'coupon_not_allowed_for_this_user':
      return 'Seu e-mail não está autorizado para uso deste cupom';
    case 'coupon_not_allowed':
      return 'Cupom não permitido';
    default:
      return '';
  }
}
