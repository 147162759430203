import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { IStore } from '../contracts';
import api from '../services/api';
import { store } from '../store';
import { signInSuccess } from '../store/ducks/auth/actions';

export default function RouteWrapper(props: any) {
  const profile = useSelector((state: IStore) => state.auth.profile);
  const dispatch = useDispatch();
  let url: any;
  let id: number;

  const params = new URLSearchParams(props?.location?.search);

  const getUtmCampaing = (url: string) => {
    if (!url) {
      return '';
    }
    const partial = url.split('utm_campaign=')[1];
    return partial ? partial.split('&')[0] : '';
  };

  const queryToken = params.get('token');
  url = params.get('url') ? params.get('url') : 'https://corridaperfeita.com';
  const utm_medium = params.get('utm_medium') ? params.get('utm_medium') : '';
  const utm_source = params.get('utm_source') ? params.get('utm_source') : '';

  url = `${url}&utm_source=${utm_source}&utm_medium=${utm_medium}`;

  const createConversion = async () => {
    const payload = {
      user_id: id,
      plan_name: '',
      origin: 'app-wall',
      utm_campaign: getUtmCampaing(url),
      utm_source,
      utm_medium,
    };

    await api.post('/checkout_view', payload);
  };

  const loadUser = async () => {
    let profileMobile: any = {};

    if (queryToken) {
      profileMobile = JSON.parse(atob(queryToken.split('.')[1]));
      api.defaults.headers.Authorization = `Bearer ${queryToken}`;
    }

    if (token || queryToken) {
      id = profile && profile.id ? profile.id : profileMobile.uid;

      await api.get(`/users/${id}`)
        .then((response) => {
          const { data } = response;
          if (queryToken) {
            dispatch(signInSuccess(queryToken, {
              id: data.id, name: data.name, email: data.email, phone: data.phone,
            }));
          }
        })
        .catch(() => { throw new Error(); });
    }
  };

  const currentUrl = window.location.href;

  const token = store.getState().auth?.token;

  if (currentUrl.includes('/redirect')) {
    loadUser()
      .catch(() => <Redirect to="/error" />)
      .then(async () => {
        try {
          await createConversion();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
        window.location.href = url;
        return <></>;
      });
    return <Redirect to="/redirecionado" />;
  }

  if (props.isPrivate && !token) {
    return <Redirect to="/error" />;
  }

  if (
    props.isPrivate
    && token
    && currentUrl.includes('/pay/success')
    && props.location.state?.prevURL !== '/pay/payment-details'
  ) {
    return <Redirect to="/error" />;
  }

  if (
    currentUrl.includes('/pay/product/')
    && !props.computedMatch.params?.product
  ) {
    return <Redirect to="/error" />;
  }

  return <Route {...props} />;
}
