import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GlobalStyle from './global';
import Routes from './routes';
import { store, persistor } from './store';

function App() {
  return (
    <GoogleOAuthProvider clientId="819167375724-1b788vfpcc1ci67fpabm3fb0npaomv7p.apps.googleusercontent.com">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Routes />
          <GlobalStyle />
          <ToastContainer autoClose={3000} />
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
