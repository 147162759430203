import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import theme from '../../theme';

type PropTypeShowModal = {
  showModal: boolean
}

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
`;

// eslint-disable-next-line no-unused-vars
export const ModalWrapper = styled.div.attrs((props: PropTypeShowModal) => ({})) < PropTypeShowModal >`
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 100%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: ${theme.colors.primary};
  z-index: 10;
  border-radius: 10px;
  margin-top: 10px;

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  margin-bottom: 40px;
  font-size: 16pt;
  color: #fff;
  width: 32px;
  height: 32px;
  padding: 0;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    color: #fff;
    text-align: center;
  }

  label {
    color: #fff;
    font-size: 16px;
  }

  input,
  select {
    padding: 15px;
    width: 100%;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: ${theme.colors.cardB3};
    color: #fff;
  }
`;

export const DataAccess = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  div {
    border: 1px #fff solid;
    width: 100%;
  }

  p {
    color: #fff;
    width: 100%;
  }

  @media (max-width: 667px) {
    p {
      font-size: 10pt;
      margin-left: 5px;
    }
  }
`;

export const AuxTextFooter = styled.div`
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #fff;

  a {
    color: #fff;
  }

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const PasswordBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: ${theme.colors.cardB3};

  input {
    border: none;
    padding: 5px;
    margin-left: 10px;
    color: #fff;
  }

  div {
    margin-right: 10px;
    cursor: pointer;
  }
`;

export const PasswordRecoveryButton = styled.a`
  color: #fff;
  margin-top: 10px;
  text-align: center;
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  font-size: 14px;
  margin-top: 20px;
  cursor: pointer;
  background: ${theme.colors.primary};
  color: #fff;
  border: 1px solid #fff;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
  }
`;

export const NextButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  font-size: 14px;
  margin-top: 20px;
  cursor: pointer;
  background: #fff;
  color: ${theme.colors.primary};
  border: none;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
  }
`;
