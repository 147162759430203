import { Reducer } from 'redux';
import { AuthState } from '../../../contracts';
import { AuthTypes } from './types';

const INITIAL_STATE: AuthState = {
  token: null,
  profile: null,
  loading: false,
  errorMessage: null,
  paymentToken: null,
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.SIGN_IN_REQUEST:
      return { ...state, loading: true };
    case AuthTypes.SIGN_IN_GOOGLE:
      return { ...state, loading: true };
    case AuthTypes.SIGN_IN_APPLE:
      return { ...state, loading: true };
    case AuthTypes.SIGN_IN_SUCCESS:
      return {
        ...state, loading: false, errorMessage: null, token: action.payload.token, profile: action.payload.profile,
      };
    case AuthTypes.SIGN_IN_FAILURE:
      return { ...state, loading: false, errorMessage: action.payload.message };
    case AuthTypes.SET_ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload.message };
    case AuthTypes.SIGN_OUT:
      return { ...state, token: null, profile: null };
    case AuthTypes.SET_PAYMENT_TOKEN:
      return { ...state, paymentToken: action.payload.token };
    case AuthTypes.UPDATE_USER_PHONE:
      return { ...state, profile: { ...state.profile!, phone: action.payload.phone } };
    default:
      return state;
  }
};

export default reducer;
