import { action } from 'typesafe-actions';
import { Profile, ProfileToUpdate, Address } from '../../../contracts';
import { AuthTypes } from './types';

export const signInRequest = (email: string, password: string) => action(AuthTypes.SIGN_IN_REQUEST, { email, password });
export const signInSuccess = (token: string, profile: Profile) => action(AuthTypes.SIGN_IN_SUCCESS, { token, profile });
export const signInFailure = (message: string) => action(AuthTypes.SIGN_IN_FAILURE, { message });
export const persistRehydrate = (auth: { token: string }) => action(AuthTypes.PERSIST_REHYDRATE, { auth });
export const setErrorMessage = (message: string) => action(AuthTypes.SET_ERROR_MESSAGE, { message });
export const signOut = () => action(AuthTypes.SIGN_OUT);
export const setPaymentToken = (token: string) => action(AuthTypes.SET_PAYMENT_TOKEN, { token });
export const updateUserProfileRequest = (id: number, profile: ProfileToUpdate, address: Address) => action(AuthTypes.UPDATE_USER_PROFILE_REQUEST, { id, profile, address });
export const updateUserProfileSelfRequest = (id: number, profile: ProfileToUpdate) => action(AuthTypes.UPDATE_USER_PROFILE_REQUEST, { id, profile });
export const updateUserPhone = (phone: string) => action(AuthTypes.UPDATE_USER_PHONE, { phone });
export const googleLogin = (userData: any) => action(AuthTypes.SIGN_IN_GOOGLE, { userData });
export const appleLogin = (userId: number, token: string, redirect: string | null) => action(AuthTypes.SIGN_IN_APPLE, { userId, token, redirect });
