import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import {
  Background,
  ModalContent,
  ModalWrapper,
  Title,
  HeaderBar,
  Email,
  CPF,
  Phone,
  ConfirmButton,
  FixButton,
} from './styles';
import { IProps } from './contracts';

export const SignUpConfirmModal = ({
  showModal, setShowModal, email, cpf, finishPayment, phone,
}: IProps) => {
  const animation = useSpring({
    to: {
      config: {
        duration: 250,
      },
      opacity: showModal ? 1 : 0,
      transform: showModal ? 'translateY(0%)' : 'translateY(100%)',
    },
  });

  return (
    <>
      {showModal ? (
        <Background>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <ModalContent>
                <HeaderBar>
                  <Title>
                    <strong>Você confirma que esses dados estão corretos?</strong>
                  </Title>
                </HeaderBar>

                <Email>
                  <strong>E-mail:</strong>
                  &nbsp;
                  {email}
                </Email>
                {
                  phone
                  && (
                    <Phone>
                      <strong>Telefone Celular:</strong>
                      &nbsp;
                      {phone}
                    </Phone>
                  )
                }
                {
                  cpf
                  && (
                    <CPF>
                      <strong>CPF:</strong>
                      &nbsp;
                      {cpf}
                    </CPF>
                  )
                }

                <ConfirmButton
                  onClick={finishPayment}
                >
                  <strong>Confirmar</strong>
                </ConfirmButton>
                <FixButton
                  onClick={setShowModal}
                >
                  <strong>Corrigir</strong>
                </FixButton>
              </ModalContent>
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};
