import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Background,
  ModalContent,
  ModalWrapper,
  CloseModalButton,
  Logo,
  AddressForm,
  CityState,
  NumberNeighborhood,
  InputMasked,
} from './styles';
import logo from '../../assets/logo.png';
import Loader from '../Loader';
import { IStore } from '../../contracts';
import { IProps, ICountry, IState } from './contracts';
import api from '../../services/api';
import viaCep from '../../services/viaCep';
import { errorMessages } from '../../constants/messages';

const CPFInput = (props: any) => (
  <InputMasked
    mask="999.999.999-99"
    defaultValue={props.defaultValue}
    onChange={props.onChange}
  />
);

const ZipcodeInput = (props: any) => (
  <InputMasked
    mask="99999-999"
    defaultValue={props.defaultValue}
    onChange={props.onChange}
  />
);

export const SaveAddressCPFModal = ({
  showModal, setShowModal, finishPayment, onlySaveAddress, loadUser,
}: IProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [country, setCountry] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [cpf, setCpf] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [complement, setComplement] = useState('');
  const [loading, setLoading] = useState(false);
  const userId = useSelector((state: IStore) => state.auth.profile?.id);
  const [states, setStates] = useState<IState[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countryError, setCountryError] = useState<string | null>(null);
  const [cpfError, setCpfError] = useState<string | null>(null);
  const [zipcodeError, setZipcodeError] = useState<string | null>(null);
  const [cityError, setCityError] = useState<string | null>(null);
  const [stateError, setStateError] = useState<string | null>(null);
  const [addressError, setAddressError] = useState<string | null>(null);
  const [numberError, setNumberError] = useState<string | null>(null);
  const [neighborhoodError, setNeighborhoodError] = useState<string | null>(null);

  useEffect(() => {
    api.get('countries')
      .then((response) => {
        setCountries(response.data);
        setCountry('Brasil');
      });
  }, []);

  useEffect(() => {
    api.get('states')
      .then((response) => {
        const states = [{ nome: 'Selecione', sigla: 'SL', cidades: ['Selecione'] }, ...response.data?.estados];
        setStates(states);
        setState('SL');
        setCity('Selecione');
      });
  }, []);

  function validateData() {
    if (!cpf) setCpfError('*Campo obrigatório');
    if (!country) setCountryError('*Campo obrigatório');
    if (!zipcode) setZipcodeError('*Campo obrigatório');
    if (!city) setCityError('*Campo obrigatório');
    if (!state) setStateError('*Campo obrigatório');
    if (!address) setAddressError('*Campo obrigatório');
    if (!number) setNumberError('*Campo obrigatório');
    if (!neighborhood) setNeighborhoodError('*Campo obrigatório');

    if (
      !country
      || !zipcode
      || !city
      || !state
      || !address
      || !number
      || !neighborhood
    ) {
      return false;
    }

    return true;
  }

  const animation = useSpring({
    to: {
      config: {
        duration: 250,
      },
      opacity: showModal ? 1 : 0,
      transform: showModal ? 'translateY(0%)' : 'translateY(-100%)',
    },
  });

  const closeModal = (e: { target: any; }) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal],
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress],
  );

  async function handleSubmit(event: { preventDefault: () => void; }) {
    event.preventDefault();

    const successValidate = validateData();

    if (!successValidate) return;

    setLoading(true);

    try {
      const data = {
        cpf,
        address: {
          country,
          zipcode: zipcode.replaceAll('-', '').replaceAll('.', '').replaceAll('/', ''),
          city,
          state,
          street: address,
          number,
          district: neighborhood,
          complement,
        },
      };

      await api.put(`users/${userId}`, data);

      setLoading(false);

      setShowModal(false);

      if (onlySaveAddress) {
        loadUser();
      } else {
        await finishPayment();
      }
    } catch (error) {
      setLoading(false);

      toast.error(errorMessages.SAVE_USER_ADDRESS_FAILURE);
    }
  }

  function handleSetZipcode(value: string) {
    const zipcodeReplaced = value.replaceAll('-', '').replaceAll('.', '').replaceAll('/', '').replaceAll('_', '');

    if (zipcodeReplaced.length === 8) {
      setLoading(true);

      viaCep.get(`${zipcodeReplaced}/json`)
        .then((response) => {
          if (response.data?.erro === true) {
            toast.error(errorMessages.ZIPCODE_NOT_FOUND);
          }

          if (response.data.logradouro) setAddress(response.data.logradouro);
          if (response.data.complemento) setComplement(response.data.complemento);
          if (response.data.bairro) setNeighborhood(response.data.bairro);
          if (response.data.localidade) setCity(response.data.localidade);
          if (response.data.uf) {
            const selectedState = states.find((st) => st.sigla === response.data.uf);

            setState(selectedState?.sigla!);
          }

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    setZipcode(value);
  }

  const handleSetState = (value: string) => {
    setState(value);
    console.log(states);
    const city = states.find((item) => item.nome === value)?.cidades[0]!;
    console.log(city);
    setCity(city);
  };

  return (
    <>
      {loading && <Loader />}
      {showModal ? (
        <Background onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <ModalContent>
                <Logo src={logo} alt="logo" />
                <AddressForm onSubmit={(e) => handleSubmit(e)}>
                  <label htmlFor="cpf">CPF</label>
                  <CPFInput
                    defaultValue={cpf}
                    onChange={(e: any) => setCpf(e.target.value)}
                    name="cpf"
                    id="cpf"
                  />
                  {cpfError && <small style={{ color: 'red', marginBottom: '5px' }}>{cpfError}</small>}
                  <label htmlFor="country">País</label>
                  <select id="country" value={country} onChange={(e) => setCountry(e.target.value)}>
                    {
                      countries.map((country) => (
                        <option key={country.nome_pais} value={country.nome_pais}>{country.nome_pais}</option>
                      ))
                    }
                  </select>
                  {countryError && <small style={{ color: 'red', marginBottom: '5px' }}>{countryError}</small>}

                  <label htmlFor="zipcode">CEP</label>
                  {
                    country === 'Brasil'
                      ? (
                        <ZipcodeInput
                          defaultValue={zipcode}
                          onChange={(e: any) => handleSetZipcode(e.target.value)}
                          name="zipcode"
                          id="zipcode"
                        />
                      )
                      : <input type="text" id="zipcode" name="zipcode" value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
                  }
                  {zipcodeError && <small style={{ color: 'red', marginBottom: '5px' }}>{zipcodeError}</small>}

                  <CityState>
                    <div id="city">
                      <label htmlFor="city">Cidade</label>
                      {
                        country === 'Brasil'
                          ? (
                            <select id="city" name="city" value={city} onChange={(e) => setCity(e.target.value)}>
                              {
                                states.find((item) => item.sigla === state)?.cidades?.map((city) => (
                                  <option key={city} value={city}>{city}</option>
                                ))
                              }
                            </select>
                          )
                          : <input type="text" name="city" id="city" value={city} onChange={(e) => setCity(e.target.value)} />
                      }
                      {cityError && <small style={{ color: 'red', marginBottom: '5px' }}>{cityError}</small>}
                    </div>

                    <div id="state">
                      <label htmlFor="state">Estado</label>
                      {
                        country === 'Brasil'
                          ? (
                            <select id="state" name="state" value={state} onChange={(e) => handleSetState(e.target.value)}>
                              {
                                states.map((state) => (
                                  <option key={state.sigla} value={state.sigla}>{state.nome}</option>
                                ))
                              }
                            </select>
                          )
                          : <input type="text" name="state" id="state" value={state} onChange={(e) => setState(e.target.value)} />
                      }
                      {stateError && <small style={{ color: 'red', marginBottom: '5px' }}>{stateError}</small>}
                    </div>
                  </CityState>

                  <label htmlFor="address">Endereço</label>
                  <input type="text" id="address" name="address" value={address} onChange={(e) => setAddress(e.target.value)} />
                  {addressError && <small style={{ color: 'red', marginBottom: '5px' }}>{addressError}</small>}

                  <NumberNeighborhood>
                    <div id="number">
                      <label htmlFor="number">Número</label>
                      <input type="text" id="number" name="number" value={number} onChange={(e) => setNumber(e.target.value)} />
                      {numberError && <small style={{ color: 'red', marginBottom: '5px' }}>{numberError}</small>}
                    </div>

                    <div id="neighborhood">
                      <label htmlFor="neighborhood">Bairro</label>
                      <input type="text" id="neighborhood" name="neighborhood" value={neighborhood} onChange={(e) => setNeighborhood(e.target.value)} />
                      {neighborhoodError && <small style={{ color: 'red', marginBottom: '5px' }}>{neighborhoodError}</small>}
                    </div>
                  </NumberNeighborhood>

                  <label htmlFor="complement">Complemento (Opcional)</label>
                  <input type="text" id="complement" name="complement" value={complement} onChange={(e) => setComplement(e.target.value)} />

                  <button type="submit">
                    <strong>Continuar</strong>
                  </button>
                </AddressForm>
              </ModalContent>
              <CloseModalButton
                aria-label="Close modal"
                onClick={() => setShowModal((prev: boolean) => !prev)}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};
