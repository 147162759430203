import styled from 'styled-components';

export const Container = styled.header`
  height: 50px;
  background: #000033;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    height: 35px;
  }
`;

export const AuthArea = styled.div`
  background-color: #000033;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  font-size: 11pt;
  color: #ffffff;

  button {
    border: none;
    background-color: #000033;
    font-size: 11pt;
    color: #ff0000;
    cursor: pointer;
  }
`;
