import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import theme from '../../theme';

type PropTypeShowModal = {
  showModal: boolean
}

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// eslint-disable-next-line no-unused-vars
export const ModalWrapper = styled.div.attrs((props: PropTypeShowModal) => ({})) < PropTypeShowModal >`
  width: 600px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: ${theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  border-radius: 10px;

  @media (max-width: 667px) {
    max-width: 350px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  color: #fff;
`;

export const IconContainer = styled.div`
  margin-top: 60px;
`;

export const Title = styled.p`
  font-size: 12pt;
  font-weight: 700;
  color: ${theme.colors.title};
  margin-top: 10px;
`;

export const ParagraphsContainer = styled.div`
  margin-top: 10px;
  padding: 10px;
`;

export const Paragraph = styled.p`
  margin-top: 10px;
  color: ${theme.colors.title};
  font-size: 11pt;
  text-align: center;
`;

export const LinkButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 45px;
  border-radius: 10px;
  font-size: 12pt;
  margin-top: 40px;
  margin-bottom: 40px;
  background: #fff;
  color: ${theme.colors.primary};
  border: none;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
  }
`;
