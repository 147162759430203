import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { useSpring, animated } from '@react-spring/web';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  Background,
  ModalContent,
  ModalWrapper,
  CloseModalButton,
  LoginForm,
  DataAccess,
  PasswordRecoveryButton,
  HeaderBar,
  PasswordBox,
  BackButton,
  NextButton,
} from './styles';
import Loader from '../Loader';
import { IProps } from './contracts';
import { IStore } from '../../contracts';
import { signInRequest, setErrorMessage } from '../../store/ducks/auth/actions';

export const EmailAlreadyExistsModal = ({
  showModal, setShowModal, emailExists, setShowModalCallback, updateUserProfileCallback,
}: IProps) => {
  const dispatch = useDispatch();

  const modalRef = useRef<HTMLDivElement>(null);

  const animation = useSpring({
    to: {
      config: {
        duration: 250,
      },
      opacity: showModal ? 1 : 0,
      transform: showModal ? 'translateY(0%)' : 'translateY(-100%)',
    },
  });

  const keyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal],
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress],
  );

  useEffect(() => {
    if (emailExists) {
      setEmail(emailExists);
    }
  }, [emailExists]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const errorMessage = useSelector((state: IStore) => state.auth.errorMessage);
  const loading = useSelector((state: IStore) => state.auth.loading);
  const token = useSelector((state: IStore) => state.auth.token);

  function handleSubmit(event: { preventDefault: () => void; }) {
    event.preventDefault();

    if (!email || !password) {
      dispatch(setErrorMessage('Preencha todos os campos'));
      return;
    }

    dispatch(signInRequest(email, password));
  }

  useEffect(() => {
    if (token) {
      setEmail('');
      setPassword('');
      setShowModal(false);
      setShowModalCallback(false);
      updateUserProfileCallback();
    }
  }, [token]);

  return (
    <>
      {loading && <Loader />}

      {showModal ? (
        <Background ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <ModalContent>
                <HeaderBar>
                  <CloseModalButton
                    aria-label="Close modal"
                    onClick={() => setShowModal((prev: boolean) => !prev)}
                  />
                </HeaderBar>

                <LoginForm onSubmit={(e) => handleSubmit(e)}>
                  <p>
                    <strong>Email já cadastrado!</strong>
                  </p>

                  <DataAccess>
                    <div />
                    <p>Fazer login</p>
                    <div />
                  </DataAccess>

                  {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

                  <label htmlFor="email">E-mail</label>
                  <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />

                  <label htmlFor="password">Senha</label>
                  <PasswordBox>
                    <input type={showPassword ? 'text' : 'password'} id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <div onClick={() => setShowPassword(!showPassword)}>
                      {showPassword
                        ? <FaEyeSlash size={25} color="#fff" />
                        : <FaEye size={25} color="#fff" />}
                    </div>
                  </PasswordBox>

                  <NextButton type="submit">
                    <strong>Continuar</strong>
                  </NextButton>

                  <PasswordRecoveryButton href={`${process.env.REACT_APP_APP2_BASE_URL}/solicitar-recuperar-senha`} target="_blank" rel="noopener noreferrer">
                    Recuperar senha
                  </PasswordRecoveryButton>

                  <BackButton type="button" onClick={() => setShowModal((prev: boolean) => !prev)}>
                    <strong>Voltar</strong>
                  </BackButton>
                </LoginForm>
              </ModalContent>
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};
