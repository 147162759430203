import React from 'react';
import logo from '../../assets/logo_corridaperfeita_branco.png';
import { IProps } from './contracts';
import { Container, Spinner, Logo } from './styles';

function ProcessingLoader(props: IProps) {
  return (
    <Container>
      <div>
        <Logo src={logo} alt="logo" />
        <Spinner />
        {props.title && <p>{props.title}</p>}
      </div>
    </Container>
  );
}

export default ProcessingLoader;
