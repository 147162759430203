import {
  createStore, Store, applyMiddleware, compose,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import rootReducer from './ducks/rootReducer';
import { AuthState } from '../contracts';
import rootSaga from './ducks/rootSaga';
import persistReducers from './persistReducers';
import tron from '../ReactotronConfig';

interface ApplicationState {
  auth?: AuthState
}

const sagaMonitor = process.env.NODE_ENV === 'development' ? tron.createSagaMonitor!() : undefined;

const tronEnhancer = process.env.NODE_ENV === 'development' ? tron.createEnhancer!() : undefined;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const composed = process.env.NODE_ENV === 'development' ? compose(applyMiddleware(sagaMiddleware), tronEnhancer!) : compose(applyMiddleware(sagaMiddleware));

const store: Store<ApplicationState & PersistPartial> = createStore(persistReducers(rootReducer), composed);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
