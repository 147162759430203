import React, {
  Dispatch, SetStateAction, useCallback, useEffect, useRef,
} from 'react';
import { animated, useSpring } from '@react-spring/web';
import { SlPresent } from 'react-icons/sl';
import {
  ModalContent,
  Background,
  ModalWrapper,
  CloseModalButton,
  IconContainer,
  Title,
  ParagraphsContainer,
  Paragraph,
  LinkButton,
} from './styles';

export interface IProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

const texts = {
  title: 'PROGRAMA DE INDICAÇÕES',
  paragraphs: [
    'A partir de agora, você pode ganhar prêmios incríveis ao indicar amigos e conhecidos para também terem a experiência do Clube Corrida Perfeita.',
    'Os prêmios podem ser até descontos nas suas próximas renovações.',
  ],
};

const ReferralIndicationInfoModal = ({
  showModal,
  setShowModal,
}: IProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const animation = useSpring({
    to: {
      config: {
        duration: 250,
      },
      opacity: showModal ? 1 : 0,
      transform: showModal ? 'translateY(0%)' : 'translateY(-100%)',
    },
  });

  const keyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal],
  );

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress],
  );

  return (
    <>
      {showModal ? (
        <Background ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <ModalContent>
                <IconContainer>
                  <SlPresent
                    size={30}
                    color="white"
                  />
                </IconContainer>

                <Title>{texts.title}</Title>

                <ParagraphsContainer>
                  {
                    texts.paragraphs.map((paragraph) => (
                      <Paragraph>{paragraph}</Paragraph>
                    ))
                  }
                </ParagraphsContainer>

                <LinkButton href="https://app.corridaperfeita.com/indication" target="_blank">
                  SAIBA MAIS
                </LinkButton>
              </ModalContent>

              <CloseModalButton
                aria-label="Close modal"
                onClick={() => setShowModal((prev: boolean) => !prev)}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};

export default ReferralIndicationInfoModal;
