import React from 'react';
import logo from '../../assets/logo_corridaperfeita_branco.png';
import { Container, Logo, Text } from './styles';

function RedirecionadoPage() {
  return (
    <Container>
      <Logo src={logo} alt="logo" />
      <Text>Você está sendo redirecionado...</Text>
    </Container>
  );
}

export default RedirecionadoPage;
