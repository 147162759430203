import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: #fff;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const rotate = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

export const Spinner = styled.div`
    border: 8px solid rgba(255, 255, 255, 0.1);
    height: 60px;
    width: 60px;
    border-left-color: #fff;
    border-radius: 50%;
    animation: ${rotate} 1s linear infinite;
    margin-bottom: 10px;
`;

export const Logo = styled.img`
  max-height: 150px;
  max-width: 150px;
  margin-bottom: 30px;
`;
