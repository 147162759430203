import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Logo = styled.img`
  max-height: 150px;
  max-width: 150px;
  margin-bottom: 30px;
`;

export const Text = styled.h4`
  color: #fff;
  font-weight: bold;
  font-size: 14pt;
  margin: 10px;
`;
