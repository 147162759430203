import {
  all, AllEffect, ForkEffect, takeLatest,
} from 'redux-saga/effects';
import {
  googleSignIn, setToken, signIn, updateUserProfile, appleSignIn,
} from './auth/sagas';
import { AuthTypes } from './auth/types';

export default function* rootSaga(): Generator<AllEffect<ForkEffect<never>>> {
  return yield all([
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
    takeLatest(AuthTypes.SIGN_IN_GOOGLE, googleSignIn),
    takeLatest(AuthTypes.SIGN_IN_APPLE, appleSignIn),
    takeLatest(AuthTypes.PERSIST_REHYDRATE, setToken),
    takeLatest(AuthTypes.UPDATE_USER_PROFILE_REQUEST, updateUserProfile),
  ]);
}
