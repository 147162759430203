import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';
import App from './App';
import './ReactotronConfig';

const tagManagerArgs = {
  gtmId: 'GTM-5HVP9T',
};

const reactGA4MeasurementId = 'G-GJX6J5FYBF';

if (process.env.NODE_ENV === 'production') {
  TagManager.initialize(tagManagerArgs);

  ReactGA.initialize(reactGA4MeasurementId);
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
